import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

/* Pages */
import Frontpage from "./pages/Frontpage";
import "antd/dist/antd.css";

const App = (props) => {
  return (
    <>
      <div style={{ minHeight: "calc(100vh - 135px)" }}>
        <Switch>
          <Route exact path="/" component={Frontpage} />
        </Switch>
      </div>
    </>
  );
};

export default App;
