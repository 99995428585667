import { useEffect, useState } from "react";
import HeroSection from "../components/HeroSection";
import getContentCategory from "../utils/getContentCategory";
import { Typography } from "antd";
import { PhoneOutlined } from "@ant-design/icons";
import "./Frontpage.scss";
const { Title, Paragraph } = Typography;

export default function Frontpage() {
  const [frontpage, setFrontpage] = useState();

  useEffect(() => {
    getContentCategory("frontpageMainTitle").then((rsp) => {
      setFrontpage(rsp.data);
    });

    /* getContentCategory("frontpage_intro").then((rsp) => {
      const res = rsp.data.items[0];
      setMainBoxRes(res);
    });

    getContentCategory("kiropraktorar").then((rsp) => {
      setOffices(rsp.data);
    });

    getContentCategory("staff_member").then((rsp) => {
      const data = {
        ...rsp.data,
        items: rsp.data.items.sort((a, b) => {
          return a.fields.name.localeCompare(b.fields.name);
        }),
      };

      setStaff(data);
    }); */
  }, []);

  return (
    <>
      {frontpage && (
        <>
          <HeroSection
            vheight={70}
            id="frontMain"
            overlayStyle={{ backgroundColor: "#333132" }}
          >
            <img
              style={{ width: 500, maxWidth: "90%" }}
              src="/images/logo_light.svg"
              alt="Logo"
            />
          </HeroSection>

          <HeroSection vheight={70} className="contactInfo">
            <div className="introText">
              <Title style={{ fontSize: 26, marginTop: 20 }}>Um okkur</Title>
              <Paragraph style={{ fontSize: 18 }}>
                Rafstoð ehf. var stofnað árið 2014 og hefur starfað sem
                löggildur rafverktaki síðan þá. Fyrirtækið er framsækið og
                starfar í þjónustu við fyrirtæki, stofnanir og einstaklinga.
                Okkar markmið eru að veita viðskiptavinum okkar ábyrga og
                trausta þjónustu með áherslu á fagleg vinnubrögð. Hjá Rafstoð
                starfa 8 starfsmenn með mikla reynslu og þekkingu á sviði
                raflagna, brunakerfa, tölvukerfa og uppsetningu hleðslustöðva.
              </Paragraph>
            </div>

            <Title style={{ fontSize: 22, marginTop: 30 }}>Hafðu samband</Title>

            <div className="contactCards">
              <div className="contactCard">
                <Title style={{ fontSize: 20 }}>Baldur Þór Jack</Title>
                <div>Eigandi / Rafiðnfræðingur</div>

                <Title style={{ fontSize: 16, marginTop: 20 }}>
                  <a href="tel:+3546995524">
                    <PhoneOutlined /> 699 5524
                  </a>
                </Title>
                <div>
                  <a href="mailto:baldur@rafstod.is">baldur@rafstod.is</a>
                </div>
              </div>
            </div>
          </HeroSection>

          <HeroSection vheight={0} className="doubleBox">
            <div className="sectionParent">
              <div className="sectionKubbur overlay">
                <img src="/images/switch.jpg" alt="Mynd" />
              </div>

              <div className="sectionKubbur">
                <div className="textKubbur">
                  <Title>Netlausnir</Title>
                  <Paragraph>
                    Við höfum víðtæka reynslu á sviði ljósleiðara og netkerfa.
                    Við sjáum um hönnun og uppsetningu á netkerfum fyrir
                    fyrirtæki, stofnanir og heimili.
                  </Paragraph>
                </div>
              </div>
            </div>
          </HeroSection>

          <HeroSection vheight={0} className="doubleBox">
            <div className="sectionParent">
              <div className="sectionKubbur">
                <div className="textKubbur">
                  <Title>Hleðslustöðvar</Title>
                  <Paragraph>
                    Við sjáum um uppsetningu og þjónustu við bílahleðslur fyrir
                    fyrirtæki, fjölbýli og einstaklinga
                  </Paragraph>
                </div>
              </div>
              <div className="sectionKubbur overlay">
                <img
                  src="/images/hleddun.jpg"
                  alt="Mynd"
                  style={{ filter: "brightness(1.6) grayscale(0.5)" }}
                />
              </div>
            </div>
          </HeroSection>

          <HeroSection vheight={0} className="doubleBox">
            <div className="sectionParent">
              <div className="sectionKubbur overlay">
                <img src="/images/kaplar.jpg" alt="Mynd" />
              </div>

              <div className="sectionKubbur">
                <div className="textKubbur">
                  <Title>Raflagnir</Title>
                  <Paragraph>
                    Hjá Rafstoð sjáum við um allar almennar raflagnir,
                    lýsingarverkefni, uppsetningu hústjórnarkerfa, tilboðsverk
                    og þjónustusamninga. Okkar markmið eru að veita
                    viðskiptavinum okkar ábyrga og trausta þjónustu með áherslu
                    lagða á fagleg vinnubrögð.
                  </Paragraph>
                </div>
              </div>
            </div>
          </HeroSection>

          <HeroSection vheight={0} className="doubleBox">
            <div className="sectionParent">
              <div className="sectionKubbur">
                <div className="textKubbur">
                  <Title>Brunavarnir</Title>
                  <Paragraph>
                    Vandvirk og fagmannleg uppsetning á brunakerfum fyrir
                    fyrirtæki og stofnanir. Einnig bjóðum við upp á viðhald og
                    ástandsprófanir á brunakerfum.
                  </Paragraph>
                </div>
              </div>
              <div className="sectionKubbur overlay">
                <img
                  src="https://images.unsplash.com/photo-1517060954848-5290be575c49?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80"
                  alt="Mynd"
                  style={{ filter: "brightness(1.4) grayscale(0.6)" }}
                />
              </div>
            </div>
          </HeroSection>

          <HeroSection vheight={0} className="doubleBox">
            <div className="sectionParent">
              <div className="sectionKubbur overlay">
                <img
                  src="https://images.unsplash.com/photo-1528312635006-8ea0bc49ec63?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1676&q=80"
                  alt="Mynd"
                />
              </div>

              <div className="sectionKubbur">
                <div className="textKubbur">
                  <Title>Myndavélakerfi</Title>
                  <Paragraph>
                    Ráðgjöf og þjónusta við myndavélakerfi fyrir fyrirtæki,
                    verslanir og heimili.
                  </Paragraph>
                </div>
              </div>
            </div>
          </HeroSection>
        </>
      )}
    </>
  );
}
