import Axios from "axios";

const spaceId = "b5otehmyi9gl";
const accessToken = "z_tkJTijR5q1kqhDwmy4DppZrXa3jhpHqj0Zw71Lnvs";

const getContentCategory = (category) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries/?content_type=${category}&access_token=${accessToken}`
  );

export const getSingleEntry = (entryId) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/entries/${entryId}?access_token=${accessToken}`
  );

export const getSingleAsset = (assetId) =>
  Axios.get(
    `https://cdn.contentful.com/spaces/${spaceId}/environments/master/assets/${assetId}?access_token=${accessToken}`
  );

export default getContentCategory;
